import React from 'react'
import { Layout, Stack, Main} from '@layout'
import PageTitle from '@elegantstack/flow-ui-components/src/PageTitle'
import Divider from '@elegantstack/flow-ui-components/src/Divider'
import Seo from '@elegantstack/flow-ui-widgets/src/Seo'

const sources = props => (
    <Layout {...props}>
        <Seo title='Useful sources'/>
        <Divider />
        <Stack>
            <Main>
                <PageTitle
                    header="Useful sources (under construction)"
                    subheader="If you have any ideas or questions you can communicate trough the contact form."
                />
                <Divider />
            </Main>
        </Stack>
    </Layout>
)

export default sources
